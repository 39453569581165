import { useAuthContext } from './useAuthContext';

export const useLogout = () => {
    const { dispatch } = useAuthContext();

    const logout = () => {
        localStorage.removeItem('hc33_login');
        dispatch({type: 'LOGOUT'});
        window.location.href = "/";
    }

    return {logout};

}