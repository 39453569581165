import React, { useEffect, useState } from 'react';

import { useAuthContext } from '../../../hooks/useAuthContext';
import { useLaunchGame } from '../../../hooks/useLaunchGame';


const SlotMachine = () => {

	const { user } = useAuthContext();

	const { startGame, isLoading, error, gameLink } = useLaunchGame();

	const [selectedGameCode, setSelectedGameCode] = useState('');
	const chooseGameLaunch = async () => {
        // e.preventDefault();
		if (selectedGameCode == '') {
			return;
		}
		await startGame(user.token, userData.username, selectedGameCode);
		var element = document.getElementById("gameFrame");
		element.scrollIntoView();
    };

	const [gamelist, setGameList] = useState();
	const [itemsPerPage, setItemsPerPage] = useState(20);
	const [pages, setPages] = useState(1);
	const getGameList = async () => {
	  const response = await fetch('https://api.meka.games/api/game-provider/joker/gamelist', {
		headers: {
		  'Authorization': `Bearer ${user.token}`
		}
	  });
	  const json = await response.json();
	  if (response.ok) {
		console.log(json);
		setGameList(json);
	  }
	}


	const [userData, setUserData] = useState();
	const getUserData = async () => {
	  const response = await fetch('https://api.meka.games/api/player/id/'+user.player_id, {
		headers: {
		  'Authorization': `Bearer ${user.token}`
		}
	  });
	  const json = await response.json();
	  if (response.ok) {
		setUserData(json);
	  }
	}





	
	useEffect(() => {
		if (user) {
			getUserData();
			getGameList();
		}
	
	}, [user]);

  	return (
		<>
			{gameLink && gameLink != '' ? (
				<div className="container-fluid py-3" style={{backgroundColor:"#000"}}>
					<div id="gameFrame" className="row">
						<div className="col-0 col-md-1 col-xl-2"></div>
						<div className="col-md-10 col-xl-8">
							<div class="game-frame-container">
								<iframe class="game-frame-item" src={gameLink} allowfullscreen></iframe>
							</div>
						</div>
						<div className="col-0 col-md-1 col-xl-2"></div>
					</div>
				</div>
			):(
				<></>
			)}

			<div className="container-fluid fluid-padding">

				<div className="row px-2 my-3">

					{/* Slots */}
					<div className="col-12 mt-3">
						<h4 className="text-gray mb-0">Slots</h4>
					</div>
					{gamelist && gamelist.ListGames.length > 0 ? (
						<div className="col-12 mt-3">
							<div className="row">
								{gamelist.ListGames && gamelist.ListGames.map((game, index) => (
									<>
										{game.GameType == "Slot" ? (
											<div className="col-4 col-sm-3 col-lg-2 px-0 text-center" key={index}>
												<button className="btn p-1" onClick={(e) => {
													setSelectedGameCode(game.GameCode);
													chooseGameLaunch();
												}}>
													<div className="game-container">
														<img className="w-100" src={game.Image1}></img>
													</div>
												</button>
											</div>
										):(
											<></>
										)}
									</>
								))}
							</div>

						</div>
					):(
						<div className="col-12 mt-3">
							<p className="text-gray">No games available for this category.</p>
						</div>
					)}
				</div>
			</div>
		</>
  	);

};

export default SlotMachine;