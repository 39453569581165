import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Navigation, FreeMode } from 'swiper';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'


SwiperCore.use([Autoplay, Pagination, Navigation, FreeMode]);

const Recent = () => {

  return (
    <div>
      <div className="container-fluid fluid-padding">
        <div className="row my-3">
          <div className="col-12 my-3">
            <h5 className="text-gray mb-0"><span className="heading-box">RECENT</span></h5>
          </div>
        </div>
      </div>
    </div>
  );

};

export default Recent;