import React, { useEffect, useState } from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MiddleEllipsis from "react-middle-ellipsis";

// Hooks
import { useAuthContext } from '../hooks/useAuthContext';

const Affiliate = () => {

	const { user } = useAuthContext();

	const [copied, setCopied] = useState(false);
	const [copyText, setCopyText] = useState("");
	const copyReferral = () => {    
	  setCopied(true);
	  setCopyText("Copied");
	  setTimeout(() => setCopied(false), 3000);
	}

	const [userData, setUserData] = useState();
	const getUserData = async () => {
	  const response = await fetch('https://api.meka.games/api/player/id/'+user.player_id, {
		headers: {
		  'Authorization': `Bearer ${user.token}`
		}
	  });
	  const json = await response.json();
	  if (response.ok) {
		setUserData(json);
	  }
	}

	useEffect(() => {
		if (user) {
		  getUserData();
		}
	  }, []);

  	return (
		<div className="container-fluid fluid-padding pb-5">
			<div className="row my-3">
				<div className="col-12 mt-3">
					<h4 className="text-gray mb-0">Affiliate Program</h4>
				</div>
				<div className="col-12 mt-3">
					<div className="page-container">
						<div className="row">
							<div className="col-xl-7">
								<h5 className="text-gray">Refer & Earn More!</h5>
								<p className="text-gray">
									Get $1,000.00 for each friend you invite, plus a up to 25% commission as they play our games (based on transaction volume). Enjoy consistent commissions, whether they win or lose, in our Casino and Sportsbook. Start earning now!
								</p>
								<p className="text-gray">
									<span className="profit-green currency-lg"><strong>$1,000</strong></span> Bonus Game Credit
									<br></br>
									Up to <span className="profit-green currency-lg"><strong>25%</strong></span> Commission
								</p>
							</div>
							{userData ? (
								<div className="col-xl-5 mt-3 mt-xl-0">
									<div className="page-sub-container">
										<h5 className="light-yellow mb-0">Referral Link</h5>
										<MiddleEllipsis><span className="referral-link text-gray mb-0">https://hiclub33.io/register/{userData._id}</span></MiddleEllipsis>
									</div>
									<CopyToClipboard 
										text={"https://hiclub33.io/register/"+userData._id}
										onCopy={() => copyReferral()}>
										<button className="btn action-button mt-2 mb-3">
											{!copied ? (
												<p className="mb-0"><strong><i className="fa-solid fa-copy mr-2"></i>Copy Link</strong></p>
											):(
												<p className="mb-0"><strong><i className="fa-solid fa-copy mr-2"></i>{copyText}</strong></p>
											)}
										</button>
									</CopyToClipboard>
								</div>
							):(
								<></>
							)}
						</div>
					</div>
				</div>
				<div className="col-12">
					<div className="row px-2">
						<div className="col-xl-4 px-2 mt-2 mt-sm-3">
							<div className="page-sub-container">
								<h5 className="text-gray mb-0">
									Total Bonus:
									<br></br>
									<span className="profit-green currency-lg">0</span>
								</h5>
							</div>
						</div>
						<div className="col-xl-4 px-2 mt-2 mt-sm-3">
							<div className="page-sub-container">
								<h5 className="text-gray mb-0">
									Total Commission:
									<br></br>
									<span className="profit-green currency-lg">0</span>
								</h5>
							</div>
						</div>
						<div className="col-xl-4 px-2 mt-2 mt-sm-3">
							<div className="page-sub-container">
								<h5 className="text-gray mb-0">
									Total Invited:
									<br></br>
									<span className="profit-green currency-lg">0</span>
								</h5>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
  	);

};

export default Affiliate;