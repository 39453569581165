import React, { Component } from "react";
import { NavLink, Link } from 'react-router-dom';

class Footer extends Component {
  render() {
    return (
      <>
        <div className="container-fluid fluid-padding footer pb-5">
          <div className="row pt-3">
            <div className="col-6 col-sm-4 col-md-3 col-xl-2 mt-3">
              <p className="footer-links-heading pastel-purple ml-3 mb-2">Games</p>
              <p className="footer-links ml-3 mb-0">E-Sports</p>
              <p className="footer-links ml-3 mb-0">Racing</p>
              <p className="footer-links ml-3 mb-0">On-Chain</p>
              <p className="footer-links ml-3 mb-0">Retro Game</p>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-xl-2 mt-3">
              <p className="footer-links-heading pastel-purple ml-3 mb-2">Sports</p>
              <Link to="#"><p className="footer-links ml-3 mb-0">Soccer</p></Link>
              <Link to="#"><p className="footer-links ml-3 mb-0">Basketball</p></Link>
              <Link to="#"><p className="footer-links ml-3 mb-0">Tennis</p></Link>
              <Link to="#"><p className="footer-links ml-3 mb-0">Football</p></Link>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-xl-2 mt-3">
              <p className="footer-links-heading pastel-purple ml-3 mb-2">Casino</p>
              <Link to="#"><p className="footer-links ml-3 mb-0">Live Casino</p></Link>
              <Link to="#"><p className="footer-links ml-3 mb-0">Slots</p></Link>
              <Link to="#"><p className="footer-links ml-3 mb-0">Black Jack</p></Link>
              <Link to="#"><p className="footer-links ml-3 mb-0">Poker</p></Link>
              <Link to="#"><p className="footer-links ml-3 mb-0">Lottery</p></Link>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-xl-2 mt-3">
              <p className="footer-links-heading pastel-purple ml-3 mb-2">Bonus</p>
              <NavLink to="/bonus/affiliate"><p className="footer-links ml-3 mb-0">Affiliate</p></NavLink>
              <Link to="/bonus/mission"><p className="footer-links ml-3 mb-0">Mission</p></Link>
              <Link to="/bonus/leaderboard"><p className="footer-links ml-3 mb-0">Leaderboard</p></Link>
              <Link to="/promotions"><p className="footer-links ml-3 mb-0">Promotions</p></Link>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-xl-2 mt-3">
              <p className="footer-links-heading pastel-purple ml-3 mb-2">Player</p>
              <Link to="#"><p className="footer-links ml-3 mb-0">Profile</p></Link>
              <Link to="#"><p className="footer-links ml-3 mb-0">Assets</p></Link>
              <Link to="#"><p className="footer-links ml-3 mb-0">Transactions</p></Link>
              <Link to="#"><p className="footer-links ml-3 mb-0">Exchange</p></Link>
              <Link to="#"><p className="footer-links ml-3 mb-0">Game History</p></Link>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-xl-2 mt-3">
              <p className="footer-links-heading pastel-purple ml-3 mb-2">Info</p>
              <p className="footer-links ml-3 mb-0">Live Support</p>
              <a className="footer-href" href="https://mekagames.io"><p className="footer-links ml-3 mb-0">Website</p></a>
              <a className="footer-href" href="https://whitepaper.mekagames.io"><p className="footer-links ml-3 mb-0">Whitepaper</p></a>
              <Link to="/terms"><p className="footer-links ml-3 mb-0">Service Terms</p></Link>
              <Link to="/privacy"><p className="footer-links ml-3 mb-0">Privacy Policy</p></Link>
            </div>
          </div>
        </div>
        <div className="container-fluid copyright-footer">
          <div className="row">
            <div className="col-12 text-center">
              <p className="copyright-text my-3">&copy; {(new Date().getFullYear())} All Rights Reserved, HiClub33</p>
            </div>
          </div>
        </div>
      </>
    );
  }
}
  
export default Footer;