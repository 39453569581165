import { useState } from 'react';

export const useLaunchGame = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [gameLink, setGameLink] = useState(null);

    const startGame = async (authToken, playerUsername, selectedGame) => {
        setIsLoading(true);
        setError(null);

        const response = await fetch('https://api.meka.games/api/game-provider/joker/generateGameToken', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify({
                username: playerUsername,
                gameCode: selectedGame
            })
        })
        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
            setError(json.error);
        }
        if (response.ok) {
            setIsLoading(false);
            // window.location.href = json.fullGameLink;
            setGameLink(json.fullGameLink);
            // window.open(json.fullGameLink, "_blank");
        }
    }

    return { startGame, isLoading, error, gameLink };
}