import React, { useEffect} from 'react';
import { useUserContext } from '../hooks/useUserContext';
import FlipCountdown from '@rumess/react-flip-countdown';


const Leaderboard = () => {

	const {users, dispatch} = useUserContext();

	useEffect(() => {
		// const fetchUsers = async () => {
		// 	const response = await fetch('http://localhost:5000/api/register');
		// 	const json = await response.json();

		// 	if (response.ok) {
		// 		dispatch({type: 'GET_USERS', payload: json})
		// 	}
		// }

		// fetchUsers();
	}, []);

  	return (
		<div>
			<div className="container-fluid fluid-padding">
				<div className="row my-3">
					<div className="col-12 mt-3">
						<h2 className="text-gray mb-0">Leaderboard</h2>
					</div>
					<div className="col-12 my-3">
						<div className="page-container">
							<div className="row">
								<div className="col-xl-6">
									<h3 className="neon-green">Play More, Win More!</h3>
									<p className="text-gray">
										0.5% of the platform daily transactions will be distributed among the top 10 players of daily, weekly and monthly basis. Play more to increase your ranking and stand a chance to win more bonus! 
									</p>
									<div className="row p-0">
										<div className="col-12 p-0">
											<button className="btn action-button mt-3 mx-2" onClick={(e) => {
											}}>
												<p className="mb-0"><strong>Daily</strong></p>
											</button>
											<button className="btn action-button mt-3 mx-2" onClick={(e) => {
											}}>
												<p className="mb-0"><strong>Weekly</strong></p>
											</button>
											<button className="btn action-button mt-3 mx-2" onClick={(e) => {
											}}>
												<p className="mb-0"><strong>Monthly</strong></p>
											</button>
										</div>
									</div>
								</div>
								<div className="col-xl-6 mt-3 mt-xl-0 px-2">
									<div className="page-sub-container">
										<h5 className="text-gray text-center mb-0">
											Distribution Countdown
										</h5>
										<FlipCountdown
											size='small' // Options (Default: medium): large, medium, small, extra-small.
											theme='dark'
											hideYear
											hideMonth
											endAtZero
											onTimeUp={() => console.log("Time's Up")}
											endAt={1714233600000} // Date/Time
										/>
									</div>
									<div className="page-sub-container text-center mt-3">
										<h3 className="text-gray mb-0">
											Current Prize Pool
											<br></br>
											<span className="profit-green currency-lg">$57,690</span>
										</h3>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-12 desktop-sm">
						<table class="table table-hover table-dark">
							<thead>
								<tr>
									<th className="p-3" scope="col"><p className="text-gray mb-0">Rank</p></th>
									<th className="p-3" scope="col"><p className="text-gray mb-0">Player</p></th>
									<th className="p-3" scope="col"><p className="text-gray mb-0">Total Volume (USDT)</p></th>
									<th className="p-3" scope="col"><p className="text-gray mb-0">Prize (USDT)</p></th>
								</tr>
							</thead>
							<tbody>
								{users && users.map((user, index) => (
									<tr key={user._id}>
										<th className="px-3 py-2"><p className="text-gray mb-0">{index+1}</p></th>
										<th className="px-3 py-2"><p className="text-gray mb-0">{user.username}</p></th>
										<th className="px-3 py-2"><p className="profit-green mb-0">${user.totalVolume}</p></th>
										<th className="px-3 py-2"><p className="profit-green mb-0">$27,852.96</p></th>
									</tr>
								))}
							</tbody>
						</table>
					</div>
					<div className="col-12 mobile-sm">
						<table class="table table-responsive table-hover table-dark">
							<thead>
								<tr>
									<th className="p-3" scope="col"><p className="text-gray mb-0">Rank</p></th>
									<th className="p-3" scope="col"><p className="text-gray mb-0">Player</p></th>
									<th className="p-3" scope="col"><p className="text-gray mb-0">Total Volume (USDT)</p></th>
									<th className="p-3" scope="col"><p className="text-gray mb-0">Prize (USDT)</p></th>
								</tr>
							</thead>
							<tbody>
								{users && users.map((user, index) => (
									<tr key={user._id}>
										<th className="px-3 py-2"><p className="text-gray mb-0">{index+1}</p></th>
										<th className="px-3 py-2"><p className="text-gray mb-0">{user.username}</p></th>
										<th className="px-3 py-2"><p className="profit-green mb-0">${user.totalVolume}</p></th>
										<th className="px-3 py-2"><p className="profit-green mb-0">$27,852.96</p></th>
									</tr>
								))}
							</tbody>
						</table>
					</div>

				</div>
			</div>

		</div>
  );

};

export default Leaderboard;