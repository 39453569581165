import React from 'react';

const TermsAndCondition = () => {

  return (
		<div className="container-fluid fluid-padding">
			<div className="row mt-3 mb-5">
				<div className="col-12">
					<h2 className="section-heading text-gray mb-0">Terms & Condition</h2>
				</div>
			</div>
		</div>
  );

};

export default TermsAndCondition;