// Dependencies
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, SubMenu, menuClasses } from 'react-pro-sidebar';
import { NavLink } from 'react-router-dom';

// Hooks
import { useLogout } from './hooks/useLogout';
import { useAuthContext } from './hooks/useAuthContext';
import { useDeposit } from './hooks/useDeposit';

// Components
import ContactUsIcon from "./components/contact-us-icon";
import Home from "./components/home";
// import Transactions from "./components/transactions";
import Footer from "./components/footer";
import JoinCommunity from "./components/join-community";

// Pages
import Login from "./pages/login";
import Register from "./pages/register";
import PlayerProfile from "./pages/player-profile";
import TermsAndCondition from "./pages/terms-and-condition";
import PrivacyPolicy from "./pages/privacy-policy";

import SlotMachine from "./pages/game/category/slot-machine";
import MobileSlotGame from "./pages/game/category/mobile-slot-game";

import PlayerWallet from "./pages/wallet";
import Promotions from "./pages/promotions";
import Bonus from "./pages/bonus";

import RecentPage from "./pages/recent";

// Bonus Reward
import Affiliate from "./pages/affiliate";
import Leaderboard from "./pages/leaderboard";

import SquareLogo from './assets/img/logo-square.png';
import MainLogo from './assets/img/logotext.png';



function App() {

  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);

  const { logout } = useLogout();
  const handleLogout = () => {
    logout();
    window.location.href= "/";
  }

  const { user } = useAuthContext();

  const [userData, setUserData] = useState();
  const getUserData = async () => {
    const response = await fetch('https://api.meka.games/api/player/id/'+user.player_id, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    });
    const json = await response.json();
    if (response.ok) {
      console.log(json);
      setUserData(json);
    }
  }

  const { playerCreateDeposit, paymentLink, isLoading, error } = useDeposit();
  const [selectedGateway, setSelectedGateway] = useState('');
  const [depositAmount, setDepositAmount] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState('');
  const handleDeposit = async (e) => {
    setPaymentStatus("Pending");
		e.preventDefault();
		await playerCreateDeposit(
      user.token,
      user.player_id,
      userData.username,
      userData.referrer,
      "MYR",
      selectedGateway,
      depositAmount,
      false
    );
    // if (paymentLink) {
    //   window.location.href = paymentLink;
    // } else {
    //   setPaymentStatus("Link Error");
    // }
	};

  useEffect(() => {
    if (user) {
      getUserData();
    }
  }, [user]);

  return (
    <Router>

      <div className="modal fade" id="createDepositModal" tabindex="-1" role="dialog" aria-labelledby="createDeposit" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-lg" role="document">
					<div className="modal-content bg-black p-3">
						<div className="modal-header" style={{borderBottom: "none"}}>
							<h4 className="modal-title vip-yellow">
                <strong>Deposit</strong>
              </h4>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true"><i className="text-gray fa-solid fa-circle-xmark"></i></span>
							</button>
						</div>
            <div className="modal-body">
              <form className="w-100" onSubmit={handleDeposit}>
                <div className="mb-3">
                  <label htmlFor="gateway">
                    <h6 className="text-gray mb-0">Payment Method</h6>
                  </label>
                  <select 
                  name='gateway'
                  className='form-control'
                  onChange={(e) => setSelectedGateway(e.target.value)}
                  >
                    <option value="">Select Payment Method</option>
                    <option value="ZPAY">ZPAY (MYR)</option>
                    <option value="" disabled>Crypto Payment (Coming Soon)</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="amount">
                    <h6 className="text-gray mb-0">Amount</h6>
                  </label>
                  <input
                  type="number"
                  step="0.01"
                  placeholder='Enter Deposit Amount'
                  autoComplete='off'
                  name='amount'
                  className='form-control'
                  onChange={(e) => setDepositAmount(e.target.value)}
                  />
                </div>
                <button type="submit" disabled={selectedGateway == "" || depositAmount == 0} className="btn action-button w-100 mt-3">
                  <p className="mb-0"><strong>Submit</strong></p>
                </button>
                {error && <div className="error">{error}</div>}
              </form>
            </div>
					</div>
				</div>
			</div>

      {/* Header */}
      <section className="header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-2 mobile-lg px-2">
              <button className="btn" onClick={() => setToggled(!toggled)}>
                <span className="collapse-btn-icon"><i className="fa-sharp fa-solid fa-bars"></i></span>
              </button>
            </div>
            <div className="col-10 col-lg-12 text-right px-2">
              {!user ? (
                <NavLink to="/login">
                  <button className="btn action-button ml-2">
                    <p className="mb-0"><strong>Login</strong></p>
                  </button>
                </NavLink>
              ):(
                <>
                  <button className="btn ok-btn mx-2 px-2" data-toggle="modal" data-target="#createDepositModal" onClick={(e) => {
                  }}>
                    <p className="mb-0">
                      <i className="fa-solid fa-circle-plus mr-2"></i>
                      <strong>Deposit</strong>
                    </p>
                  </button>
                  <button className="btn action-button mr-2 px-2" onClick={(e) => {
                    getUserData();
                  }}> 
                    {userData ? (
                      <p className="mb-0">
                        <i className="fa-solid fa-wallet mr-2"></i>
                        <strong>{userData && parseFloat(Number(userData.cashBalance)+Number(userData.creditBalance)).toFixed(2)}</strong>
                        <i className="fa-solid fa-arrows-rotate ml-2"></i>
                      </p>
                    ):(
                      <p className="mb-0">
                        <i className="fa-solid fa-wallet mr-2"></i>
                        <strong>Refresh</strong>
                        <i className="fa-solid fa-arrows-rotate ml-2"></i>
                      </p>
                    )}

                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </section>

      <ContactUsIcon/>
      
      <div className="d-flex">
        <Sidebar 
          transitionDuration={300}
          width="260px"
          backgroundColor="#0C0C0C"
          breakPoint="lg"
          onBackdropClick={() => setToggled(false)}
          toggled={toggled}
          rootStyles={{
            height: '100vh',
            zIndex: '110',
            border: 'none'
          }}
        > 
          <NavLink to="/">
            <div className="side-menu-brand-area">
              {!collapsed ? (
                <img className="side-menu-logotext" src={MainLogo}></img>
              ):(
                <img className="side-menu-logo" src={SquareLogo}></img>
              )}

            </div>
          </NavLink>

          <Menu
            renderExpandIcon={({ open }) => 
              <span className="collapse-icon">
                {open ? <i className="fa-duotone fa-caret-down mt-2"></i> : <i className="fa-duotone fa-caret-right mt-2"></i>}
              </span>
            }
            rootStyles={{
              ['.' + menuClasses.button]: {
                backgroundColor: '#0C0C0C',
                color: '#DCDCDC',
                '&:hover': {
                  backgroundColor: '#a28b51',
                },
              },
              ['.active']: {
                backgroundColor: '#a17b1c',
              }
            }}
          >

            <MenuItem 
              icon={<span className="side-menu-icon"><i className="fa-sharp-duotone fa-solid fa-fire"></i></span>}
              component={<NavLink to="/" activeClassName={['menu-item'].active}/>}
            > 
              <p className="side-menu-text">Hot Pick</p>
            </MenuItem>

            <MenuItem 
              icon={<span className="side-menu-icon"><i className="fa-duotone fa-solid fa-clock-rotate-left"></i></span>}
              component={<NavLink to="/recent" activeClassName={['menu-item'].active}/>}
            > 
              <p className="side-menu-text">Recent</p>
            </MenuItem>

            <MenuItem
              icon={<span className="side-menu-icon"><i className="fa-duotone fa-slot-machine"></i></span>}
              component={<NavLink to="/game/category/mobile-slot-games" activeClassName={['menu-item'].active}/>}
            > 
              <p className="side-menu-text">Mobile Games</p>
            </MenuItem>

            <SubMenu
              icon={<span className="side-menu-icon"><i className="fa-duotone fa-gamepad-modern"></i></span>} 
              label={<p className="side-menu-text">Games</p>}
            >
              <MenuItem
                icon={<span className="side-menu-icon"><i className="fa-duotone fa-slot-machine"></i></span>}
                component={<NavLink to="/game/category/slots" activeClassName={['menu-item'].active}/>}
              > 
                <p className="side-menu-text">Slots</p>
              </MenuItem>
              <MenuItem
                icon={<span className="side-menu-icon"><i className="fa-duotone fa-dice"></i></span>}
              > 
                <p className="side-menu-text">Live Casino<span className="side-menu-coming-soon ml-2">Soon</span></p>
              </MenuItem>
              <MenuItem
                icon={<span className="side-menu-icon"><i className="fa-duotone fa-alien-8bit"></i></span>}
              > 
                <p className="side-menu-text">E-Sports<span className="side-menu-coming-soon ml-2">Soon</span></p>
              </MenuItem>
              <MenuItem 
                icon={<span className="side-menu-icon"><i className="fa-duotone fa-ticket"></i></span>}
              > 
                <p className="side-menu-text">Lottery<span className="side-menu-coming-soon ml-2">Soon</span></p>
              </MenuItem>
              <MenuItem
                icon={<span className="side-menu-icon"><i className="fa-duotone fa-flag-checkered"></i></span>}
              > 
                <p className="side-menu-text">Racing<span className="side-menu-coming-soon ml-2">Soon</span></p>
              </MenuItem>

              <SubMenu
                icon={<span className="side-menu-icon"><i className="fa-duotone fa-court-sport"></i></span>} 
                label={<p className="side-menu-text">Sports Game</p>}
              >
                <MenuItem
                  icon={<span className="side-menu-icon"><i className="fa-duotone fa-futbol"></i></span>}
                > 
                  <p className="side-menu-text">Soccer<span className="side-menu-coming-soon ml-2">Soon</span></p>
                </MenuItem>
                <MenuItem
                  icon={<span className="side-menu-icon"><i className="fa-duotone fa-basketball-hoop"></i></span>}
                > 
                  <p className="side-menu-text">Basketball<span className="side-menu-coming-soon ml-2">Soon</span></p>
                </MenuItem>
                <MenuItem
                  icon={<span className="side-menu-icon"><i className="fa-duotone fa-tennis-ball"></i></span>}
                > 
                  <p className="side-menu-text">Tennis<span className="side-menu-coming-soon ml-2">Soon</span></p>
                </MenuItem>
                <MenuItem
                  icon={<span className="side-menu-icon"><i className="fa-duotone fa-sportsball"></i></span>}
                > 
                  <p className="side-menu-text">Football<span className="side-menu-coming-soon ml-2">Soon</span></p>
                </MenuItem>
              </SubMenu>
            </SubMenu>

            <MenuItem 
              icon={<span className="side-menu-icon"><i className="fa-duotone fa-badge-percent"></i></span>}
              component={<NavLink to="/promotions" activeClassName={['menu-item'].active}/>}
            > 
              <p className="side-menu-text">Promotions</p>
            </MenuItem>

            <MenuItem 
              icon={<span className="side-menu-icon"><i className="fa-duotone fa-gift"></i></span>}
              component={<NavLink to="/bonus" activeClassName={['menu-item'].active}/>}
            > 
              <p className="side-menu-text">Bonus</p>
            </MenuItem>

            <MenuItem 
              icon={<span className="side-menu-icon"><i className="fa-duotone fa-users-medical"></i></span>}
              component={<NavLink to="/affiliate" activeClassName={['menu-item'].active}/>}
            > 
              <p className="side-menu-text">Affiliate Program</p>
            </MenuItem>

            {user ? (
              <>
                <MenuItem 
                  icon={<span className="side-menu-icon"><i className="fa-solid fa-user"></i></span>}
                  component={<NavLink to="/profile" activeClassName={['menu-item'].active}/>}
                > 
                  <p className="side-menu-text">Profile</p>
                </MenuItem>

                <MenuItem 
                  icon={<span className="side-menu-icon"><i className="fa-solid fa-wallet"></i></span>}
                  component={<NavLink to="/wallet" activeClassName={['menu-item'].active}/>}
                > 
                  <p className="side-menu-text">Wallet</p>
                </MenuItem>

                <MenuItem 
                  icon={<span className="side-menu-icon"><i className="fa-solid fa-right-from-bracket"></i></span>}
                  onClick={handleLogout}
                > 
                  <p className="side-menu-text">Log Out</p>
                </MenuItem>
              </>
            ):(
              <></>
            )}

          </Menu>

        </Sidebar>

        <main className="main-content">

          <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/login" element={!user ? <Login/> : <Navigate to="/" />}/>
            <Route path="/register" element={!user ? <Register/> : <Navigate to="/" />}/>
            <Route path="/register/:referrerUsername" element={!user ? <Register/> : <Navigate to="/" />}/>

            <Route path="/recent" element={<RecentPage/>}/>

            {/* Games */}
            <Route path="/game/category/mobile-slot-games" element={<MobileSlotGame/>}/>
            <Route path="/game/category/slots" element={<SlotMachine/>}/>

            <Route path="/profile" element={<PlayerProfile/>}/>
            <Route path="/wallet" element={<PlayerWallet/>}/>
            <Route
              path="/promotions"
              element={<Promotions/>}
            />
            <Route
              path="/bonus"
              element={<Bonus/>}
            />
            <Route
              path="/affiliate"
              element={<Affiliate/>}
            />
            <Route
              path="/leaderboard"
              element={<Leaderboard/>}
            />
            <Route path="/terms" element={<TermsAndCondition/>}/>
            <Route path="/privacy" element={<PrivacyPolicy/>}/>
          </Routes>
          <JoinCommunity/>
        </main>

      </div>

    </Router>
  );
}

export default App;