import React, { Component } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Navigation, FreeMode } from 'swiper';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

// Images
import HomeBanner1 from "../assets/img/banner/homebanner1.jpg";
import HomeBanner2 from "../assets/img/banner/homebanner2.jpg";
import HomeBanner3 from "../assets/img/banner/homebanner3.jpg";
import HomeBanner4 from "../assets/img/banner/homebanner4.jpg";
import HomeBanner5 from "../assets/img/banner/homebanner5.jpg";

import game1 from "../assets/img/games/918kaya.png";
import game2 from "../assets/img/games/918kiss.png";
import game3 from "../assets/img/games/evo888.png";
import game4 from "../assets/img/games/joker.png";
import game5 from "../assets/img/games/king855.png";
import game6 from "../assets/img/games/live22.png";
import game7 from "../assets/img/games/mega.png";
import game8 from "../assets/img/games/newtown.png";
import game9 from "../assets/img/games/pussy888.png";
import game10 from "../assets/img/games/rollex.png";
import game11 from "../assets/img/games/scr888.png";
import game12 from "../assets/img/games/xe88.png";

import GPAllbet from "../assets/img/game-provider/allbet.png";
import GPEvolution from "../assets/img/game-provider/evolution.png";
import GPEvoplay from "../assets/img/game-provider/evoplay.png";
import GPKAGaming from "../assets/img/game-provider/ka-gaming.png";
import GPRedTiger from "../assets/img/game-provider/red-tiger.png";
import GPSAGamingbet from "../assets/img/game-provider/sa-gamingbet.png";
import GPEzugi from "../assets/img/game-provider/ezugi.png";
import GPSpribe from "../assets/img/game-provider/spribe.png";
import GPTFGaming from "../assets/img/game-provider/tf-gaming.png";
import GPSpadeGaming from "../assets/img/game-provider/spade-gaming.png";
import GPIAEsport from "../assets/img/game-provider/ia-esport.png";


SwiperCore.use([Autoplay, Pagination, Navigation, FreeMode]);

class Home extends Component {

  render() {
    return (
      <div>
        <div className="container-fluid">
          {/* Home Banner */}
          <div className="row">
            <div className="col-12 p-0">
              <Swiper
                loop
                grabCursor={true}
                slidesPerView={1}
                speed={1000}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
              >
                <SwiperSlide><img class="page-banner" src={HomeBanner1} alt="Homepage Banner"></img></SwiperSlide>
                <SwiperSlide><img class="page-banner" src={HomeBanner2} alt="Homepage Banner"></img></SwiperSlide>
                <SwiperSlide><img class="page-banner" src={HomeBanner3} alt="Homepage Banner"></img></SwiperSlide>
                <SwiperSlide><img class="page-banner" src={HomeBanner4} alt="Homepage Banner"></img></SwiperSlide>
                <SwiperSlide><img class="page-banner" src={HomeBanner5} alt="Homepage Banner"></img></SwiperSlide>
              </Swiper>
            </div>
          </div>

          {/* Hot Games */}
          <div className="row mt-3 mb-5">
            <div className="col-12 my-3">
              <h5 className="text-gray mb-0"><span className="heading-box">RECENT WINS</span></h5>
            </div>
            <div className="col-12 mt-2">
              <Swiper
                loop
                freeMode={true}
                grabCursor={true}
                slidesPerView={3}
                spaceBetween={5}
                speed={500}
                autoplay={{
                  delay: 1000,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  399: {
                    slidesPerView: 5,
                    spaceBetween: 10,
                  },
                  576: {
                    slidesPerView: 6,
                    spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 8,
                    spaceBetween: 10,
                  },
                  1199: {
                    slidesPerView: 10,
                    spaceBetween: 10,
                  },
                  1400: {
                    slidesPerView: 13,
                    spaceBetween: 10,
                  }
                }}
              >
                <SwiperSlide>
                  <div className="game-box text-center">
                    <img class="hot-games-img" src={game1} alt="game-cover"></img>
                    <p className="text-white mb-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                    <p className="profit-green mb-2" style={{fontSize:"10px"}}><strong>+1000 USDT</strong></p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="game-box text-center">
                    <img class="hot-games-img" src={game1} alt="game-cover"></img>
                    <p className="text-white mb-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                    <p className="profit-green mb-2" style={{fontSize:"10px"}}><strong>+1000 USDT</strong></p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="game-box text-center">
                    <img class="hot-games-img" src={game1} alt="game-cover"></img>
                    <p className="text-white mb-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                    <p className="profit-green mb-2" style={{fontSize:"10px"}}><strong>+1000 USDT</strong></p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="game-box text-center">
                    <img class="hot-games-img" src={game1} alt="game-cover"></img>
                    <p className="text-white mb-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                    <p className="profit-green mb-2" style={{fontSize:"10px"}}><strong>+1000 USDT</strong></p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="game-box text-center">
                    <img class="hot-games-img" src={game1} alt="game-cover"></img>
                    <p className="text-white mb-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                    <p className="profit-green mb-2" style={{fontSize:"10px"}}><strong>+1000 USDT</strong></p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="game-box text-center">
                    <img class="hot-games-img" src={game1} alt="game-cover"></img>
                    <p className="text-white mb-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                    <p className="profit-green mb-2" style={{fontSize:"10px"}}><strong>+1000 USDT</strong></p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="game-box text-center">
                    <img class="hot-games-img" src={game1} alt="game-cover"></img>
                    <p className="text-white mb-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                    <p className="profit-green mb-2" style={{fontSize:"10px"}}><strong>+1000 USDT</strong></p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="game-box text-center">
                    <img class="hot-games-img" src={game1} alt="game-cover"></img>
                    <p className="text-white mb-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                    <p className="profit-green mb-2" style={{fontSize:"10px"}}><strong>+1000 USDT</strong></p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="game-box text-center">
                    <img class="hot-games-img" src={game1} alt="game-cover"></img>
                    <p className="text-white mb-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                    <p className="profit-green mb-2" style={{fontSize:"10px"}}><strong>+1000 USDT</strong></p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="game-box text-center">
                    <img class="hot-games-img" src={game1} alt="game-cover"></img>
                    <p className="text-white mb-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                    <p className="profit-green mb-2" style={{fontSize:"10px"}}><strong>+1000 USDT</strong></p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="game-box text-center">
                    <img class="hot-games-img" src={game1} alt="game-cover"></img>
                    <p className="text-white mb-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                    <p className="profit-green mb-2" style={{fontSize:"10px"}}><strong>+1000 USDT</strong></p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="game-box text-center">
                    <img class="hot-games-img" src={game1} alt="game-cover"></img>
                    <p className="text-white mb-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                    <p className="profit-green mb-2" style={{fontSize:"10px"}}><strong>+1000 USDT</strong></p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="game-box text-center">
                    <img class="hot-games-img" src={game1} alt="game-cover"></img>
                    <p className="text-white mb-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                    <p className="profit-green mb-2" style={{fontSize:"10px"}}><strong>+1000 USDT</strong></p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="game-box text-center">
                    <img class="hot-games-img" src={game1} alt="game-cover"></img>
                    <p className="text-white mb-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                    <p className="profit-green mb-2" style={{fontSize:"10px"}}><strong>+1000 USDT</strong></p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="game-box text-center">
                    <img class="hot-games-img" src={game1} alt="game-cover"></img>
                    <p className="text-white mb-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                    <p className="profit-green mb-2" style={{fontSize:"10px"}}><strong>+1000 USDT</strong></p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="game-box text-center">
                    <img class="hot-games-img" src={game1} alt="game-cover"></img>
                    <p className="text-white mb-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                    <p className="profit-green mb-2" style={{fontSize:"10px"}}><strong>+1000 USDT</strong></p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="game-box text-center">
                    <img class="hot-games-img" src={game1} alt="game-cover"></img>
                    <p className="text-white mb-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                    <p className="profit-green mb-2" style={{fontSize:"10px"}}><strong>+1000 USDT</strong></p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="game-box text-center">
                    <img class="hot-games-img" src={game1} alt="game-cover"></img>
                    <p className="text-white mb-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                    <p className="profit-green mb-2" style={{fontSize:"10px"}}><strong>+1000 USDT</strong></p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="game-box text-center">
                    <img class="hot-games-img" src={game1} alt="game-cover"></img>
                    <p className="text-white mb-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                    <p className="profit-green mb-2" style={{fontSize:"10px"}}><strong>+1000 USDT</strong></p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="game-box text-center">
                    <img class="hot-games-img" src={game1} alt="game-cover"></img>
                    <p className="text-white mb-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                    <p className="profit-green mb-2" style={{fontSize:"10px"}}><strong>+1000 USDT</strong></p>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>

          {/* Hot Games */}
          <div className="row mt-3 mb-5">
            <div className="col-12 my-3">
              <h5 className="text-gray mb-0"><span className="heading-box">MOBILE GAMES</span></h5>
            </div>
            <div className="col-12 mt-2">
              <Swiper
                loop
                freeMode={true}
                grabCursor={true}
                slidesPerView={2}
                spaceBetween={10}
                speed={1000}
                autoplay={{
                  delay: 1000,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  576: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                  },
                  768: {
                    slidesPerView: 4,
                    spaceBetween: 15,
                  },
                  1200: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                  }
                }}
              >
                <SwiperSlide><img class="hot-games-img" src={game1} alt="game-cover"></img></SwiperSlide>
                <SwiperSlide><img class="hot-games-img" src={game2} alt="game-cover"></img></SwiperSlide>
                <SwiperSlide><img class="hot-games-img" src={game3} alt="game-cover"></img></SwiperSlide>
                <SwiperSlide><img class="hot-games-img" src={game4} alt="game-cover"></img></SwiperSlide>
                <SwiperSlide><img class="hot-games-img" src={game5} alt="game-cover"></img></SwiperSlide>
                <SwiperSlide><img class="hot-games-img" src={game6} alt="game-cover"></img></SwiperSlide>
                <SwiperSlide><img class="hot-games-img" src={game7} alt="game-cover"></img></SwiperSlide>
                <SwiperSlide><img class="hot-games-img" src={game8} alt="game-cover"></img></SwiperSlide>
                <SwiperSlide><img class="hot-games-img" src={game9} alt="game-cover"></img></SwiperSlide>
                <SwiperSlide><img class="hot-games-img" src={game10} alt="game-cover"></img></SwiperSlide>
                <SwiperSlide><img class="hot-games-img" src={game11} alt="game-cover"></img></SwiperSlide>
                <SwiperSlide><img class="hot-games-img" src={game12} alt="game-cover"></img></SwiperSlide>
              </Swiper>
            </div>
          </div>

          {/* Providers */}
          <div className="row my-5">
            <div className="col-12 my-3">
              <h5 className="text-gray mb-0"><span className="heading-box">GAME PROVIDERS</span></h5>
            </div>
            <div className="col-12 mt-2">
              <Swiper
                loop
                freeMode={true}
                grabCursor={true}
                slidesPerView={2}
                spaceBetween={10}
                speed={1000}
                autoplay={{
                  delay: 1500,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  576: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                  },
                  768: {
                    slidesPerView: 4,
                    spaceBetween: 15,
                  },
                  1600: {
                    slidesPerView: 6,
                    spaceBetween: 15,
                  },
                }}
              >
                <SwiperSlide><img class="game-provider-img" src={GPAllbet} alt="Game Provider Logo"></img></SwiperSlide>
                <SwiperSlide><img class="game-provider-img" src={GPEvoplay} alt="Game Provider Logo"></img></SwiperSlide>
                <SwiperSlide><img class="game-provider-img" src={GPEvolution} alt="Game Provider Logo"></img></SwiperSlide>
                <SwiperSlide><img class="game-provider-img" src={GPRedTiger} alt="Game Provider Logo"></img></SwiperSlide>
                <SwiperSlide><img class="game-provider-img" src={GPKAGaming} alt="Game Provider Logo"></img></SwiperSlide>
                <SwiperSlide><img class="game-provider-img" src={GPSAGamingbet} alt="Game Provider Logo"></img></SwiperSlide>
                <SwiperSlide><img class="game-provider-img" src={GPSpadeGaming} alt="Game Provider Logo"></img></SwiperSlide>
                <SwiperSlide><img class="game-provider-img" src={GPEzugi} alt="Game Provider Logo"></img></SwiperSlide>
                <SwiperSlide><img class="game-provider-img" src={GPIAEsport} alt="Game Provider Logo"></img></SwiperSlide>
                <SwiperSlide><img class="game-provider-img" src={GPTFGaming} alt="Game Provider Logo"></img></SwiperSlide>
                <SwiperSlide><img class="game-provider-img" src={GPSpribe} alt="Game Provider Logo"></img></SwiperSlide>
              </Swiper>
            </div>
          </div>

        </div>

      </div>
    );
  }
}
  
export default Home;