import { useState } from 'react';

export const useSignup = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    const signup = async (password, phone, referrer) => {
        setIsLoading(true);
        setError(null);

        const response = await fetch('https://api.meka.games/api/player/signup', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({
                password,
                phone,
                referrer
            })
        })
        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
			setError(json.error);
		}
		if (response.ok) {
            setIsLoading(false);
            window.location.href = "/login";
		}
    }

    return { signup, isLoading, error };
}