import React, { Component} from "react";

import BrandLogo from './brand-logo';
import MainLogo from '../assets/img/logotext.png';

class JoinCommunity extends Component {
  render() {
    return (
      <>
        <section className="community-section">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <img className="community-logo mb-3" src={MainLogo} alt="Meka Logo"></img>
              </div>
              <div className="col-12 text-center">
                <h4 className="text-gray mb-0">TRUSTED & SECURED<br></br><span className="light-yellow">ONLINE CASINO GAMING</span></h4>
              </div>
            </div>
            <BrandLogo/>
          </div>
        </section>
      </>
    );
  }
}
  
export default JoinCommunity;