import React, { useEffect, useState } from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MiddleEllipsis from "react-middle-ellipsis";
import Moment from 'moment';

// Hooks
import { useAuthContext } from '../hooks/useAuthContext';

const Wallet = () => {

	const { user } = useAuthContext();

	const [userData, setUserData] = useState();
	const getUserData = async () => {
	  const response = await fetch('https://api.meka.games/api/player/id/'+user.player_id, {
		headers: {
		  'Authorization': `Bearer ${user.token}`
		}
	  });
	  const json = await response.json();
	  console.log(json);
	  if (response.ok) {
		setUserData(json);
	  }
	}

	const [userDepositsData, setUserDepositsData] = useState();
	const getUserDeposits = async () => {
	  const response = await fetch('https://api.meka.games/api/deposit/all/'+user.player_id, {
		headers: {
		  'Authorization': `Bearer ${user.token}`
		}
	  });
	  const json = await response.json();
	  console.log(json);
	  if (response.ok) {
		setUserDepositsData(json);
	  }
	}
  
	useEffect(() => {
	  if (user) {
		getUserData();
		getUserDeposits();
	  }
	}, [user]);

  	return (
		<div className="container-fluid fluid-padding pb-5">
			<div className="row my-3">
				<div className="col-12 mt-3">
					<h4 className="text-gray mb-0">Wallet</h4>
				</div>
				{userData ? (
					<>
						<div className="col-12 mt-3">
							<div className="page-container">
								<div className="row mt-3 mb-3">
									<div className="col-12 px-2">
										<h5 className="text-gray"><i className="fa-solid fa-wallet mr-2"></i>Summary</h5>
										<div className="page-sub-container">
											<h5 className="light-yellow mb-0">Wallet Balance</h5>
											<p className="currency-lg text-gray mb-0">
												{parseFloat(Number(userData.cashBalance)+Number(userData.creditBalance)).toFixed(2)}
											</p>
										</div>
									</div>
									{/* <div className="col-sm-6 mt-2 mt-sm-3 px-2">
										<div className="page-sub-container">
											<h5 className="light-yellow mb-0">Cash Wallet</h5>
											<p className="currency-sm text-gray mb-0">{parseFloat(userData.cashBalance).toFixed(2)}</p>
										</div>
									</div>
									<div className="col-sm-6 mt-2 mt-sm-3 px-2">
										<div className="page-sub-container">
											<h5 className="light-yellow mb-0">Bonus Wallet</h5>
											<p className="currency-sm text-gray mb-0">{parseFloat(userData.creditBalance).toFixed(2)}</p>
										</div>
									</div> */}
								</div>
							</div>
						</div>
						<div className="col-12 mt-5">
							<h4 className="text-gray mb-0">Deposit History</h4>
						</div>
						{userDepositsData && userDepositsData.length > 0 ? (
							<div className="col-12 mt-3">
								<table className="table table-responsive-md table-hover table-striped table-dark">
									<thead className="thead-dark">
									<tr className="">
										<th className="p-3" scope="col"><p className="mb-0">Date</p></th>
										<th className="p-3" scope="col"><p className="mb-0">Payment Method</p></th>
										<th className="p-3" scope="col"><p className="mb-0">Amount</p></th>
										<th className="p-3" scope="col"><p className="mb-0">Status</p></th>
									</tr>
									</thead>
									<tbody>
									{userDepositsData && userDepositsData.map((deposit, index) => (
										<tr key={index}>
										<td className="px-3 py-2">
											<p className="text-gray mb-0">{Moment(deposit.createdAt).format('YYYY/MM/DD HH:mm:ss')}</p>
										</td>
										<td className="px-3 py-2">
											<p className="text-gray mb-0">{deposit.gateway}</p>
											<p className="text-gray mb-0">{deposit.txn_code}</p>
										</td>
										<td className="px-3 py-2">
											<p className="profit-green mb-0"><strong>{parseFloat(deposit.req_amount).toFixed(2)}</strong></p>
										</td>
										<td className="px-3 py-2">
											<p className="mb-0">{deposit.remarks}</p>
										</td>
										</tr>
									))}
									</tbody>
								</table>
							</div>
						):(
							<div className="col-12 mt-3">
								<p className="text-gray mb-2">No record is found for this account. Make a deposit now to start gaming!</p>
								<button className="btn ok-btn mt-2 mb-3" data-toggle="modal" data-target="#createDepositModal" onClick={(e) => {
								}}>
									<p className="mb-0">
										<i className="fa-solid fa-circle-plus mr-2"></i>
										<strong>Deposit</strong>
									</p>
								</button>
							</div>
						)}
					</>
				):(
					<></>
				)}
			</div>
		</div>
  	);

};

export default Wallet;